import type { GetStaticPropsContext } from 'next'
import { Error404Page } from '../app/components/Error404Page'
import { PageLinksContext } from '../app/contexts/PageLinksContext'
import { PageLinksFragment } from '../app/data/content/PageLinksFragment'
import { Thunder } from '../generated/content'
import { getZeusConnection } from '../libs/next/loaders/graphql'

type PromiseValue<V> = V extends Promise<infer X> ? X : V
export type ErrorPageProps = Exclude<
	PromiseValue<ReturnType<typeof getStaticProps>>['props'],
	undefined
>

export default function ErrorPage({ pageLinks }: ErrorPageProps) {
	return (
		<PageLinksContext.Provider value={pageLinks}>
			<Error404Page />
		</PageLinksContext.Provider>
	)
}

export const getStaticProps = async (_context: GetStaticPropsContext) => {
	const content = getZeusConnection(Thunder, 'content')
	const data = await content.query({
		...PageLinksFragment(),
	})

	const pageLinks = {
		homePage: data.getHomePage?.link?.url ?? '/',
		portfolioPage: data.getPortfolioPage?.link?.url ?? '/',
	}

	return {
		props: {
			pageLinks,
		},
	}
}
